import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { generatePDF } from "./generatePDF";
import ResultsTabs from "./ResultsTabs";
import ResultsSummary from "./ResultsSummary";

const ResultsDialog = ({
  open,
  onClose,
  results,
  submittedPolygonArea,
  map,
}) => {
  const handleDownloadPDF = () => {
    generatePDF({
      title: "Nitrate Risk Assessment Results",
      map,
      results,
      submittedPolygonArea,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          maxHeight: "calc(100% - 64px)",
          width: "calc(100% - 64px)",
          maxWidth: "1200px",
        },
      }}
    >
      <DialogTitle>
        Results
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ResultsSummary
          data={results.data}
          submittedPolygonArea={submittedPolygonArea}
        />
        <ResultsTabs results={results} />
        <Box mt={2} textAlign="right">
          <Button variant="outlined" onClick={handleDownloadPDF}>
            Download Report
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResultsDialog;
