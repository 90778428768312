import React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Grid,
  List,
  Tooltip,
  Link,
  ListItemButton,
  Typography,
  Toolbar as MuiToolbar,
} from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";

// Styled Components
const BrandLogo = styled("img")(({ theme }) => ({
  height: 45,
  [theme.breakpoints.down("lg")]: {
    height: 40,
  },
  marginRight: theme.spacing(2),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: 64,
  [theme.breakpoints.up("md")]: {
    minHeight: 96,
  },
}));

function Footer() {
  const { isUpMd, isUpLg } = useBreakpoints();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor: (theme) => theme.header.background,
          color: (theme) => theme.header.color,
        }}
      >
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {/* Logo Section */}
            <Grid item>
              {isUpMd && (
                <Link
                  href="https://lrewater.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Tooltip
                    title="Built by LRE Water"
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.main,
                      },
                      "& .MuiTooltip-arrow": {
                        color: (theme) => theme.palette.secondary.main,
                      },
                    }}
                  >
                    <BrandLogo
                      src="/static/img/branding/lrewater-logo-simple.svg"
                      alt="LREWater.com"
                    />
                  </Tooltip>
                </Link>
              )}
            </Grid>

            {/* Links Section */}
            <Grid item>
              <List sx={{ display: "flex", gap: 2 }}>
                {/* Contact Link */}
                <ListItemButton
                  component="a"
                  href="mailto:dandersen@lpnnrd.org"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      fontWeight: { xs: 500, lg: 600 },
                    }}
                  >
                    Contact
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  component="a"
                  href="https://dtenbensel.github.io/watershed_LPNNRD/LPNNRD/data/quality_proj.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      fontWeight: { xs: 500, lg: 600 },
                    }}
                  >
                    {isUpLg
                      ? "Lower Platte North Water Quality Program"
                      : "LPN WQ Program"}
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  component="a"
                  href="https://clearinghouse.nebraska.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      fontWeight: { xs: 500, lg: 600 },
                    }}
                  >
                    {isUpLg
                      ? "NE Groundwater Quality Clearinghouse"
                      : "NE GW Clearinghouse"}
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  component="a"
                  href="https://extensionpubs.unl.edu/publication/ec2008/2023/pdf/view/ec2008-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      fontWeight: { xs: 500, lg: 600 },
                    }}
                  >
                    {isUpLg
                      ? "Irrigation and Nitrogen Management for NRDs"
                      : "Irrigation & N Mgmt, NRDs"}
                  </Typography>
                </ListItemButton>
              </List>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* Spacer Toolbar to offset content above the fixed footer */}
      <Toolbar />
    </>
  );
}

export default Footer;
