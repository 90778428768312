import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthGuard from "../components/AuthGuard";
import Landing from "../pages/presentation/Landing";
import PublicMap from "../pages/publicMap";
import { ROUTES } from "../constants";
import AuthVisibilityFilter from "../components/AuthVisibilityFilter";
import { People, Map } from "@mui/icons-material";

const landingRoutes = {
  id: "Landing Page",
  path: ROUTES.LANDING,
  component: Landing,
  children: null,
};

// id is for sidebar parent
// name is for sidebar child
const nitrateToolRoute = {
  header: "Tools",
  id: "Nitrate Risk Assessment Tool",
  icon: <Map />,
  path: ROUTES.MAP,
  name: "Nitrate Risk Assessment Tool",
  component: PublicMap,
  guard: AuthGuard,
  visibilityFilter: AuthVisibilityFilter,
  config: {
    // drawerOpenOverride will override the default drawerOpen state. If true the drawer will be open,
    // if false the drawer will be closed. If excluded null the fallback is localStorage ?? true.
    drawerOpenOverride: false,
    // headerTitleOverride will override the default header title.
    headerTitleOverride: "Nitrate Risk Assessment Tool",
  },
};

const accountRoutes = {
  id: "Account",
  icon: <People />,
  children: [
    {
      path: ROUTES.USER_LOGOUT,
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
    },
  ],
  guard: AuthGuard,
};

export const dashboardLayoutRoutes = [accountRoutes];

export const dashboardMaxContentLayoutRoutes = [nitrateToolRoute];

export const authLayoutRoutes = [];

export const presentationLayoutRoutes = [landingRoutes];

export const sidebarRoutes = [nitrateToolRoute];
