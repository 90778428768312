import React from "react";
import Header from "./AppBar";
import Hero from "./Hero";
import Introduction from "./Introduction";
import Footer from "./Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
      <Introduction />
      {/*Jon Mohr asked to remove on 12/24/24 for LPN demo. Will likely come back in some capacity with different content.*/}
      {/*<Funding />*/}
      <Footer />
    </>
  );
};

export default Landing;
