export const DEFAULT_PARAMETERS_INPUT_VALUES = [
  { option_ndx: 1, weight: 1, input_type_ndx: 1, label: "Land Cover" },
  {
    option_ndx: 13,
    weight: 1,
    input_type_ndx: 4,
    label: "Irrigation Practices",
  },
  {
    option_ndx: 22,
    weight: 1,
    input_type_ndx: 8,
    label: "Nitrogen Application Rate",
  },
];

export const POLYGON_DRAW_STYLES = [
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#f00",
      "line-width": 3,
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#ff0",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#00f",
      "fill-opacity": 0.3,
    },
  },
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#0f0",
      "fill-opacity": 0.2,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#f00",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "active", "true"]],
    paint: {
      "circle-radius": 10,
      "circle-color": "#f00",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-halo-inactive",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "active", "false"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
    },
  },
];

export const POLYGON_MAX_ACREAGE = 2800;
