import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Modal, Box, Tooltip, Button } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./styles.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import { INIT_MAP_CONFIG } from "./constants";
import { useApp } from "../../AppProvider";
import { useMap } from "./hooks/useMap";
import useSources from "./hooks/useSources";
import useFilters from "./hooks/useFilters";
import useLayerStyles from "./hooks/useLayerStyles";

import Map from "./Map";
import Search from "./filters/Search";
import WellStylesControl from "./controls/WellStylesControl";
import FilterControl from "./filters/FilterControl";
import Filter from "./filters/Filter";
import DisclaimerDialog from "./components/DisclaimerDialog";
import MainControl from "./controls/MainControl/";
import PopupControl from "./controls/PopupControl";
import Loader from "../../components/Loader";
import TogglesControl from "./filters/TogglesControl";
import MapDevTools from "./controls/MapDevTools";
import MapExportControl from "./components/MapExportControl";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet-async";
import PolygonToolControl from "./components/polygonTool/components/PolygonToolControl";
import TutorialModal from "./components/TutorialModal";

const FiltersBarRoot = styled(Paper)(({ theme }) => ({
  alignItems: "center",
  borderBottom: "1px solid #ddd",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(6),
  padding: "12px 16px 22px 40px",
}));

const FiltersSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const FiltersContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  flex: "1 1 0",
}));

const PublicMap = () => {
  const mapContainer = useRef(null);
  const { currentUser } = useApp();
  const { sources } = useSources();

  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    isMapLoaded,
  } = useMap(mapContainer, INIT_MAP_CONFIG, sources);

  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  } = useFilters({ onFilterChange: updateLayerFilters });

  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
    filterValues,
  });

  const handleSearchSelect = useCallback(
    (result) => {
      map?.flyTo({ center: result?.geometry?.coordinates, zoom: 16 });
    },
    [map]
  );

  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  useEffect(() => {
    const tutorialCompleted = localStorage.getItem("tutorialCompleted");
    if (!tutorialCompleted && isMapLoaded) {
      setIsTutorialOpen(true);
    }
  }, [isMapLoaded]);
  const handleTutorialOpen = () => {
    setIsTutorialOpen(true);
  };
  const handleTutorialClose = () => {
    setIsTutorialOpen(false);
    localStorage.setItem("tutorialCompleted", "true");
  };

  const renderFilters = (filters, renderComponent) =>
    filters.map((filter, index) => (
      <FilterControl key={index} filter={filter}>
        {renderComponent(filter)}
      </FilterControl>
    ));

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Helmet title="Interactive Map" />
      <Modal open={!isMapLoaded}>
        <div style={{ height: "100%" }}>
          <Loader size={120} />
        </div>
      </Modal>

      {process.env.NODE_ENV !== "development" && !!sources?.length && (
        <DisclaimerDialog />
      )}

      <div>
        <PerfectScrollbar>
          <FiltersBarRoot>
            <FiltersSection>
              <Tooltip
                title="Nitrate Risk Assessment Tool Tutorial"
                placement="bottom-end"
              >
                <Button
                  onClick={handleTutorialOpen}
                  color="primary"
                  variant="outlined"
                  startIcon={<HelpOutlineIcon />}
                >
                  Tutorial
                </Button>
              </Tooltip>
            </FiltersSection>

            <Search onSelect={handleSearchSelect} sources={sources} />

            <FiltersSection>
              <FiltersContainer>
                {renderFilters(
                  Object.values(filterValues).filter(
                    ({ type, excludeFilter }) =>
                      type === "multi-select" && !excludeFilter
                  ),
                  (filter) => (
                    <Filter
                      onChange={handleFilterValues}
                      onSelectAll={handleSelectAll}
                      onSelectNone={handleSelectNone}
                      filter={filter}
                    />
                  )
                )}
              </FiltersContainer>
            </FiltersSection>

            <FiltersSection>
              <FiltersContainer>
                <TogglesControl
                  filters={Object.values(filterValues).filter(
                    (f) => f.type === "boolean"
                  )}
                >
                  <Box display="flex" flexDirection="column">
                    {Object.values(filterValues)
                      .filter((f) => f.type === "boolean")
                      .map((fv) => (
                        <Filter
                          key={fv.name}
                          onChange={handleFilterValues}
                          filter={fv}
                        />
                      ))}
                  </Box>
                </TogglesControl>
              </FiltersContainer>
            </FiltersSection>

            <FiltersSection>
              <FiltersContainer>
                <FilterControl
                  width="250px"
                  label={`Color wells by ${activeStyle?.name}`}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Color wells by
                  </Typography>
                  <WellStylesControl
                    label="Color wells by"
                    name="wellStyles"
                    onChange={handleActiveStyle}
                    options={styleOptions}
                    value={activeStyle?.id}
                  />
                </FilterControl>
              </FiltersContainer>
            </FiltersSection>

            <MapExportControl map={map} />
          </FiltersBarRoot>
        </PerfectScrollbar>
      </div>

      <Map ref={mapContainer}>
        {isMapLoaded && (
          <>
            <PolygonToolControl map={map} />

            <MainControl
              activeBasemap={activeBasemap}
              basemaps={basemaps}
              layers={layers}
              onBasemapChange={updateBasemap}
              filters={filterValues}
              onLayerChange={updateLayerVisibility}
              onOpacityChange={updateLayerOpacity}
            />
          </>
        )}

        {currentUser?.isDeveloper && map && <MapDevTools map={map} />}
        <TutorialModal open={isTutorialOpen} onClose={handleTutorialClose} />
        <PopupControl />
      </Map>
    </Box>
  );
};

export default PublicMap;
