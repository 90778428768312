import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const steps = [
  "Zoom to an individual or group of parcels",
  "Select 'Risk Tool Controls'",
  "Draw a polygon around the field boundary",
  "Select current land cover, irrigation, and fertilizer practices",
  "Hit 'run scenario' to create a baseline of risk for the field or selected area",
  "To forecast risk reduction with the application of other conservation practices, select 'Add BMP'; rerun scenario",
  "To save the scenario, hit 'save scenario'; you can rename it based on the farm, name, or other local identifier",
];

const TutorialModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>How to Use the Risk Tool</DialogTitle>
      <DialogContent dividers>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{`Step ${index + 1}`}</StepLabel>
              <Box sx={{ mb: 2 }}>
                <Typography>{label}</Typography>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Box mt={3}>
          <Typography variant="body2">
            <strong>Tip:</strong> Look for the{" "}
            <InfoIcon color="secondary" style={{ verticalAlign: "middle" }} />{" "}
            icons in the Risk Tool Controls for more details about each section
            of the tool
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack} disabled={activeStep === 0}>
          Back
        </Button>
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        ) : (
          <Button onClick={handleClose} variant="contained" color="primary">
            Finish
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TutorialModal;
