import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Container, Typography } from "@mui/material";
import { HealthAndSafety } from "@mui/icons-material";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "../../../constants";
import { useHistory } from "react-router-dom";

// Styled Components
const HeroContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "50vh",
  backgroundImage: "url(/static/img/landing/hero.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "white",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    minHeight: "40vh",
  },
}));

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const StyledSafetyIcon = styled(HealthAndSafety)(({ theme }) => ({
  fontSize: "5rem",
  color: "#fff",
  marginRight: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    fontSize: "4rem",
    marginRight: 0,
    marginBottom: theme.spacing(1),
  },
}));

const ContentContainer = styled(Container)({
  padding: "32px 0",
  zIndex: 2,
  position: "relative",
});

const Title = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  letterSpacing: "6px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(1),
  },
}));

const HorizontalLine = styled("div")({
  width: "75%",
  height: "3px",
  backgroundColor: "white",
  margin: "48px auto 64px auto",
});

const Headline = styled(Typography)({
  textTransform: "uppercase",
  letterSpacing: "7px",
});

const Hero = () => {
  const { isXs, isSm } = useBreakpoints();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push(ROUTES.MAP);
    } else {
      loginWithRedirect({ appState: { returnTo: ROUTES.MAP } });
    }
  };

  const buttonText = isAuthenticated
    ? isXs
      ? "Dashboard"
      : "View Dashboard"
    : isXs
    ? "Log in"
    : "Log in to Dashboard";

  return (
    <HeroContainer>
      <Overlay />
      <ContentContainer>
        <Box
          display={isSm ? "block" : "flex"}
          alignItems="center"
          justifyContent="center"
        >
          <StyledSafetyIcon />
          <Title variant={isSm ? "h2" : "h1"} sx={{ fontWeight: "bold" }}>
            Nitrate Risk Assessment Tool for Nebraska’s Groundwater
          </Title>
        </Box>
        <HorizontalLine />
        <Headline variant="h4">
          An educational tool for citizens and agricultural producers to learn
          how to reduce nitrate infiltration into groundwater.
        </Headline>
        <Button
          sx={{ mt: { xs: 7, md: 12 } }}
          color="secondary"
          variant="contained"
          onClick={handleButtonClick}
          size={isXs ? "small" : isSm ? "medium" : "large"}
        >
          {buttonText}
        </Button>
      </ContentContainer>
    </HeroContainer>
  );
};

export default Hero;
