export const APP_DETAILS = {
  TITLE: "Lower Platte North Natural Resources District Dashboard",
  SHORT_TITLE: "Lower Platte North NRD Dashboard",
  NAME: "Lower Platte North Natural Resources District",
  SHORT_NAME: "Lower Platte North NRD",
  ABBREVIATED_NAME: "LPNNRD",
  ICONS: {
    LOGO_FULL: "/static/img/branding/lowerplattenorth-logo-full.png",
    LOGO_FULL_WHITE:
      "/static/img/branding/lowerplattenorth-logo-full-white.png",
    LOGO_SIMPLE: "/static/img/branding/lowerplattenorth-logo-simple.png",
    LOGO_SIMPLE_WHITE:
      "/static/img/branding/lowerplattenorth-logo-simple-white.png",
  },
  URL: "https://lpnnrd.org/",
};

export const ROUTES = {
  LANDING: "/",
  MAP: "/map",
  USER_LOGOUT: "/auth/logout",
  SPLASH: "/login",
};

export const APP_THEME = {
  LAYOUT: {
    SIDEBAR_WIDTH: 258,
  },
  DATE_FORMATS: {
    LONG: "MMMM do yyyy, h:mma",
    SHORT: "MM/dd/yyyy",
  },
  TYPOGRAPHY: {
    HEADER_FONT: "Arial, sans-serif",
  },
};

export const MUI_THEME = {
  COLORS: {
    PRIMARY: "#3e485b",
    SECONDARY: "#2F9BA9",
  },
};

export const REACT_QUERY_CONFIG = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

export const CRUD_LOOKUP_TABLES = [];
