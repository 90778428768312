import React, { memo, useCallback, useState } from "react";
import { styled, darken, alpha } from "@mui/material/styles";
import { NavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
  Avatar,
  Badge,
  ButtonBase,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { sidebarRoutes as routes } from "../Routes/index";
import { useAuth0 } from "@auth0/auth0-react";
import UserDropdown from "./UserDropdown";
import { customSecondary } from "../theme/variants";
import { APP_DETAILS, APP_THEME } from "../constants";

const Drawer = styled(MuiDrawer)({
  borderRight: 0,
  "& > div": {
    borderRight: 0,
  },
});

const Scrollbar = styled(PerfectScrollbar)(({ theme }) => ({
  backgroundColor: theme.sidebar.background,
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
}));

const List = styled(MuiList)(({ theme }) => ({
  backgroundColor: theme.sidebar.background,
}));

const Items = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

export const Brand = styled(ListItem)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.sidebar.header.color,
  backgroundColor: theme.sidebar.header.background,
  fontFamily: theme.typography.fontFamily,
  minHeight: 56,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  justifyContent: "flex-start",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    minHeight: 64,
  },
  "&:hover": {
    backgroundColor: theme.sidebar.header.brand.hover,
  },
}));

export const BrandIcon = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const Category = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(7),
  fontWeight: theme.typography.fontWeightRegular,
  "& svg": {
    color: theme.sidebar.color,
    fontSize: "20px",
    width: "20px",
    height: "20px",
    opacity: 0.5,
  },
  "&:hover": {
    background: "rgba(0, 0, 0, 0.08)",
  },
  "&.active": {
    backgroundColor: darken(theme.sidebar.background, 0.03),
    "& span": {
      color: theme.sidebar.color,
    },
  },
}));

const CategoryText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  "& span": {
    color: theme.sidebar.color,
    fontSize: `${theme.typography.body1.fontSize}px`,
    padding: `0 ${theme.spacing(4)}`,
  },
}));

const CategoryIconLess = styled(ExpandLess)(({ theme }) => ({
  color: alpha(theme.sidebar.color, 0.5),
}));

const CategoryIconMore = styled(ExpandMore)(({ theme }) => ({
  color: alpha(theme.sidebar.color, 0.5),
}));

const Link = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(17.5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  "& span": {
    color: alpha(theme.sidebar.color, 0.7),
  },
  "&:hover span": {
    color: alpha(theme.sidebar.color, 0.9),
  },
  "&:hover": {
    backgroundColor: darken(theme.sidebar.background, 0.015),
  },
  "&.active": {
    backgroundColor: darken(theme.sidebar.background, 0.03),
    "& span": {
      color: theme.sidebar.color,
    },
  },
}));

const LinkText = styled(ListItemText)(({ theme }) => ({
  color: theme.sidebar.color,
  "& span": {
    fontSize: `${theme.typography.body1.fontSize}px`,
  },
  marginTop: 0,
  marginBottom: 0,
}));

const LinkBadge = styled(Chip)(({ theme }) => ({
  fontSize: "11px",
  fontWeight: theme.typography.fontWeightBold,
  height: "20px",
  position: "absolute",
  right: "28px",
  top: "8px",
  background: theme.sidebar.badge.background,
  "& span.MuiChip-label, & span.MuiChip-label:hover": {
    cursor: "pointer",
    color: theme.sidebar.badge.color,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const CategoryBadge = styled(LinkBadge)({
  top: "12px",
});

const SidebarSection = styled(Typography)(({ theme }) => ({
  color: customSecondary[500],
  padding: `${theme.spacing(4)} ${theme.spacing(7)} ${theme.spacing(1)}`,
  opacity: 0.9,
  fontWeight: theme.typography.fontWeightBold,
  display: "block",
}));

const SidebarFooter = styled("div")(({ theme }) => ({
  backgroundColor: `${theme.sidebar.footer.background} !important`,
  padding: `${theme.spacing(2.75)} ${theme.spacing(4)}`,
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
}));

const SidebarFooterText = styled(Typography)(({ theme }) => ({
  color: theme.sidebar.footer.color,
}));

const SidebarFooterSubText = styled(Typography)(({ theme }) => ({
  color: theme.sidebar.footer.color,
  fontSize: "0.7rem",
  display: "block",
  padding: "1px",
}));

const SidebarFooterBadge = styled(Badge)(({ theme }) => ({
  marginRight: theme.spacing(1),
  "& span": {
    backgroundColor: theme.sidebar.footer.online.background,
    border: `1.5px solid ${theme.palette.common.white}`,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
}));

const drawerWidth = APP_THEME.LAYOUT.SIDEBAR_WIDTH;

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const children = route.children || [];
    const isOpen = route.open;
    let isActive =
      pathName.indexOf(route.path) === 0 ||
      children.filter((x) => x.path === pathName).length > 0;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen,
    });
  });

  return _routes;
};

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  onClick,
  ...rest
}) => {
  return (
    <ButtonBase
      component="div"
      onClick={onClick}
      sx={{ width: "100%", justifyContent: "flex-start" }}
    >
      <Category {...rest}>
        {icon}
        <CategoryText>{name}</CategoryText>
        {isCollapsable ? (
          isOpen ? (
            <CategoryIconMore />
          ) : (
            <CategoryIconLess />
          )
        ) : null}
        {badge ? <CategoryBadge label={badge} /> : ""}
      </Category>
    </ButtonBase>
  );
};

const SidebarLink = ({ name, to, badge, externalLink = false }) => {
  const linkProps = externalLink
    ? {
        component: "a",
        href: to,
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {
        component: NavLink,
        to: to,
        exact: true,
        activeClassName: "active",
      };

  return (
    <Link {...linkProps} dense>
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const Sidebar = ({ location, mobile, open, onClose }) => {
  const { isAuthenticated, user } = useAuth0();

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = useCallback((index) => {
    setOpenRoutes((prevOpenRoutes) => {
      const newOpenRoutes = {};

      // Close all routes except the one clicked
      Object.keys(prevOpenRoutes).forEach((key) => {
        newOpenRoutes[key] = false;
      });

      // Toggle the clicked route
      newOpenRoutes[index] = !prevOpenRoutes[index];

      return newOpenRoutes;
    });
  }, []);

  return (
    <Drawer
      variant={mobile ? "temporary" : "persistent"}
      open={open}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <ButtonBase
        component="a"
        href={APP_DETAILS.URL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ width: "100%", pointerEvents: "all" }}
      >
        <Brand>
          <BrandIcon
            src={APP_DETAILS.ICONS.LOGO_FULL_WHITE}
            height="48"
            alt={APP_DETAILS.SHORT_NAME}
          />
        </Brand>
      </ButtonBase>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => {
              const VisibilityFilter =
                category.visibilityFilter || React.Fragment;

              return (
                <VisibilityFilter key={index}>
                  <React.Fragment>
                    {category.header ? (
                      <SidebarSection>{category.header}</SidebarSection>
                    ) : null}

                    {category.children && category.icon ? (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          onClick={() => toggle(index)}
                        />

                        <Collapse
                          in={openRoutes[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {category.children.map((route, index) => {
                            const VisibilityFilter =
                              route.visibilityFilter || React.Fragment;
                            return (
                              <VisibilityFilter key={index}>
                                <SidebarLink
                                  key={index}
                                  name={route.name}
                                  to={route.path}
                                  icon={route.icon}
                                  badge={route.badge}
                                  externalLink={route.externalLink}
                                />
                              </VisibilityFilter>
                            );
                          })}
                        </Collapse>
                      </React.Fragment>
                    ) : category.link === "external" ? (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        exact
                        badge={category.badge}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ) : category.icon ? (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        exact
                        badge={category.badge}
                      />
                    ) : null}
                  </React.Fragment>
                </VisibilityFilter>
              );
            })}
          </Items>
        </List>
      </Scrollbar>
      {isAuthenticated && (
        <SidebarFooter>
          <Grid container spacing={2}>
            <Grid item>
              <UserDropdown>
                <SidebarFooterBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar alt={user.name} src={user.picture} />
                </SidebarFooterBadge>
              </UserDropdown>
            </Grid>
            <Grid item>
              <SidebarFooterText variant="body2">{user.name}</SidebarFooterText>
              <SidebarFooterSubText variant="caption">
                {user.email}
              </SidebarFooterSubText>
            </Grid>
          </Grid>
        </SidebarFooter>
      )}
    </Drawer>
  );
};

export default withRouter(memo(Sidebar));
