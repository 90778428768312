import React, { useCallback, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import {
  CssBaseline,
  IconButton,
  Snackbar,
  Alert,
  GlobalStyles,
  Toolbar,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { APP_THEME } from "../constants";
import { useApp } from "../AppProvider";
import useBreakpoints from "../hooks/useBreakpoints";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = APP_THEME.LAYOUT.SIDEBAR_WIDTH;

const globalStyles = (theme) => ({
  html: { height: "100%" },
  body: {
    height: "100%",
    background: theme.palette.background?.default || "#f0f0f0",
  },
  "#root": { height: "100%" },
  ".MuiCardHeader-action .MuiIconButton-root": {
    padding: 4,
    width: 28,
    height: 28,
  },
  ".MuiMenu-paper": {
    border: `1px solid ${theme.palette?.divider || "#e0e0e0"}`,
  },
  ".success": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.success?.main || "#4caf50",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.success?.main || "#4caf50",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.success?.main || "#4caf50",
    },
  },
  ".warning": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.warning?.main || "#ff9800",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.warning?.main || "#ff9800",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.warning?.main || "#ff9800",
    },
  },
  ".info": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.info?.main || "#2196f3",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.info?.main || "#2196f3",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.info?.main || "#2196f3",
    },
  },
  ".error": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.error?.main || "#f44336",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.error?.main || "#f44336",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.error?.main || "#f44336",
    },
  },
  ".ellipsis": {
    boxSizing: "border-box",
    minWidth: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "input:-webkit-autofill, textarea:-webkit-autofill, .MuiOutlinedInput-input:-webkit-autofill, select:-webkit-autofill":
    {
      WebkitBoxShadow: `0 0 0px 1000px ${
        theme.palette.background?.toolbar || "#ffffff"
      } inset !important`,
      WebkitTextFillColor: theme.palette.text?.secondary || "#000000",
    },
});

const Root = styled("div")({
  display: "flex",
  minHeight: "100vh",
});

const AppContent = styled("main", {
  shouldForwardProp: (prop) => !["open", "mobile"].includes(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open, mobile }) => mobile || open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

const MainContent = styled("div")(({ theme }) => ({
  flex: 1,
  background: theme.palette.background?.default || "#ffffff",
  "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
    flex: "none",
  },
  ".MuiPaper-root .MuiPaper-root": {
    boxShadow: "none",
  },
}));

const SidebarButton = styled(IconButton)(({ theme, open }) => ({
  position: "fixed",
  top: 81,
  left: open ? `${drawerWidth - 18}px` : `-1px`,
  width: 36,
  height: 36,
  borderTopLeftRadius: open ? "50%" : 0,
  borderBottomLeftRadius: open ? "50%" : 0,
  border: "1px solid rgba(0, 0, 0, 0.2)",
  backgroundColor: `${
    theme.palette.background?.default || "#ffffff"
  } !important`,
  transition: theme.transitions.create(
    ["left", "borderTopLeftRadius", "borderBottomLeftRadius"],
    {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }
  ),
  zIndex: 1300,
  "&:hover": {
    backgroundColor: `${
      theme.palette.background?.toolbar || "#e0e0e0"
    } !important`,
  },
}));

const Toaster = () => {
  const { toastOpen, toastMessage, toastSeverity, toastOptions, setToastOpen } =
    useApp();
  const duration = toastOptions?.persist
    ? null
    : toastOptions?.duration
    ? toastOptions.duration
    : 6000;

  return (
    <Snackbar
      open={toastOpen}
      key={toastMessage}
      onClose={() => setToastOpen(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={duration}
    >
      <Alert
        onClose={() => setToastOpen(false)}
        variant="filled"
        severity={toastSeverity}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

const ComponentBody = ({ children, config, contentWidth }) => {
  const theme = useTheme();

  const { drawerOpenOverride } = config;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(
    typeof drawerOpenOverride === "boolean"
      ? drawerOpenOverride
      : JSON.parse(localStorage.getItem("isMainSidebarOpen")) ?? true
  );

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prev) => {
      const newDrawerOpen = !prev;
      localStorage.setItem("isMainSidebarOpen", newDrawerOpen.toString());
      return newDrawerOpen;
    });
  }, []);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyles styles={globalStyles(theme)} />

      {!isMobile && (
        <SidebarButton open={drawerOpen} onClick={handleDrawerToggle}>
          {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </SidebarButton>
      )}

      <Sidebar
        mobile={isMobile}
        open={drawerOpen}
        onClose={handleDrawerToggle}
      />

      <AppContent open={drawerOpen} mobile={isMobile}>
        <Header
          open={drawerOpen}
          mobile={isMobile}
          onDrawerToggle={handleDrawerToggle}
          config={config}
        />
        <Toolbar />
        <MainContent sx={{ p: contentWidth }}>{children}</MainContent>
        <Footer />
        <Toaster />
      </AppContent>
    </Root>
  );
};

export const Dashboard = ({ children, config }) => {
  const { isUpLg, isUpSm } = useBreakpoints();
  const contentWidth = isUpLg ? 12 : isUpSm ? 8 : 5;
  return (
    <ComponentBody config={config} contentWidth={contentWidth}>
      {children}
    </ComponentBody>
  );
};

export const DashboardMaxContent = ({ children, config }) => {
  return (
    <ComponentBody config={config} contentWidth={0}>
      {children}
    </ComponentBody>
  );
};
