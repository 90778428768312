import React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  Button,
  IconButton,
  Toolbar,
  Grid,
  Typography,
  ButtonBase,
} from "@mui/material";
import { Menu as MenuIcon, Home as HomeIcon } from "@mui/icons-material";
import UserDropdown from "./UserDropdown";
import { APP_DETAILS, APP_THEME, ROUTES } from "../constants";
import useBreakpoints from "../hooks/useBreakpoints";
import { NavLink } from "react-router-dom";
import { Brand, BrandIcon } from "./Sidebar";

const drawerWidth = APP_THEME.LAYOUT.SIDEBAR_WIDTH;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => !["open", "mobile"].includes(prop),
})(({ theme }) => ({
  background: theme.header.background,
  color: theme.header.color,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open, mobile }) => open && !mobile,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const AppBarComponent = ({ onDrawerToggle, open, mobile, config }) => {
  const { isUpMd } = useBreakpoints();
  const { headerTitleOverride: title } = config;

  return (
    <AppBar
      position="fixed"
      elevation={0}
      width="100%"
      open={open}
      mobile={mobile}
    >
      <Toolbar sx={{ paddingLeft: "0!important" }}>
        {!open && (
          <ButtonBase
            component="a"
            href={APP_DETAILS.URL}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              pointerEvents: "all",
              display: { md: "inline-flex", xs: "none" },
            }}
          >
            <Brand sx={{ width: drawerWidth }}>
              <BrandIcon
                src={APP_DETAILS.ICONS.LOGO_FULL_WHITE}
                height="48"
                alt={APP_DETAILS.SHORT_NAME}
              />
            </Brand>
          </ButtonBase>
        )}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          ml={3}
        >
          <Grid item display="flex" alignItems="center" gap={3}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
              sx={{
                svg: {
                  width: 22,
                  height: 22,
                },
                display: { md: "none", xs: "inline-flex" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant={isUpMd ? "h3" : "h6"}
              noWrap
              sx={{
                color: (theme) => theme.sidebar.header.color || "inherit",
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              {title || ""}
            </Typography>
          </Grid>
          <Grid item display="flex" alignItems="center" gap={3}>
            <Button
              sx={{ marginRight: 1 }}
              variant="contained"
              component={NavLink}
              to={ROUTES.LANDING}
              color="secondary"
              startIcon={<HomeIcon />}
            >
              Home
            </Button>

            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
