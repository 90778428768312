import React from "react";
import {
  Box,
  List,
  ListItem,
  Link,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Circle } from "@mui/icons-material";
import useBreakpoints from "../../../hooks/useBreakpoints";

// Styled Components
const Root = styled(Box)({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
});

const SectionContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: 1200,
  display: "flex",
  alignItems: "center",
  paddingLeft: 64,
  margin: "96px 0",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    paddingLeft: 0,
  },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  backgroundColor: "#f4f1eb",
  marginLeft: 224,
  marginRight: 24,
  padding: "96px 160px 96px 192px",
  boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: 8,
  transition: "box-shadow 0.3s ease",
  [theme.breakpoints.down("lg")]: {
    padding: "72px 96px 72px 144px",
  },
  [theme.breakpoints.down("md")]: {
    padding: 32,
    marginLeft: 24,
    marginBottom: 16,
  },
}));

const MapContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: 600,
  width: 350,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    height: 500,
  },
  [theme.breakpoints.down("md")]: {
    height: 350,
    width: "80%",
    position: "relative",
    flexDirection: "row",
    alignItems: "start",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const ImageStyled = styled("img", {
  shouldForwardProp: (prop) =>
    !["scaleOnHover", "scaleDirection"].includes(prop),
})(({ theme, scaleOnHover = 1.4, scaleDirection = "left" }) => ({
  objectFit: "contain",
  width: "100%",
  transition: "transform 0.5s ease",
  transformOrigin: "left center",
  "&:hover": {
    transform: `scale(${scaleOnHover})`,
  },
  [theme.breakpoints.down("md")]: {
    transformOrigin: scaleDirection,
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&:hover": {
      transform: "none",
    },
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: 800,
  letterSpacing: 6,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    letterSpacing: 4,
  },
}));

const Introduction = () => {
  const { isSm, isUpLg } = useBreakpoints();

  return (
    <Root>
      <SectionContainer>
        <InfoContainer>
          <SectionTitle color="secondary" variant={isSm ? "h2" : "h1"}>
            Project Information
          </SectionTitle>
          <Typography
            color="primary"
            variant="body2"
            component="div"
            gutterBottom
            lineHeight={2}
          >
            The purpose of this tool is to provide one-on-one education for
            producers and citizens on best management practices aimed at{" "}
            <Typography
              color="secondary"
              component="span"
              sx={{
                fontWeight: 600,
              }}
            >
              reducing nitrate leaching
            </Typography>{" "}
            in the area. Nitrate contamination of groundwater is a management
            priority for individuals and communities working to safeguard public
            water supplies.
          </Typography>
          <Typography variant={isUpLg ? "h4" : "h5"} gutterBottom>
            This interactive map-based application features:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <Circle color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <strong>Hydrogeologic data and map layers</strong> from
                    LPNNRD’s 2023 Hydrogeologic Assessment, based on well logs
                    and test holes from the University of Nebraska Conservation
                    and Survey Division.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Circle color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <strong>Certified irrigated acres</strong> data from LPNNRD.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Circle color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <strong>Recent nitrate concentration data</strong> collected
                    by LPNNRD staff.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Circle color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <strong>A soil-water balance model</strong> to simulate
                    groundwater recharge.
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Typography variant="body2">
            Please contact <strong>Daryl Andersen</strong> at{" "}
            <Link
              sx={{ fontWeight: 600 }}
              href="mailto:dandersen@lpnnrd.org"
              color="secondary"
            >
              <strong>dandersen@lpnnrd.org</strong>
            </Link>{" "}
            if you are interested in acquiring a login for this tool.
          </Typography>
        </InfoContainer>
        <MapContainer>
          <ImageStyled
            component="img"
            src="/static/img/landing/study-area.png"
            alt="study area"
            sx={{ height: { md: "65%", sm: "100%", xs: "65%" } }}
          />
          <ImageStyled
            component="img"
            src="/static/img/landing/state-map.png"
            alt="state map"
            sx={{ height: { md: "35%", sm: "100%", xs: "35%" } }}
            scaleDirection="right"
          />
        </MapContainer>
      </SectionContainer>
    </Root>
  );
};

export default Introduction;
